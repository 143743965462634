import { dasherizeKeys } from '@zoocasa/node-kit/objects';
import { camelize } from '@zoocasa/node-kit/strings';
import { HttpRequestMethodTypes } from 'types';
import endpoint from 'utils/endpoint';
import { SavedSearch, SavedSearchImpl } from './types';
import { ThemeNames } from 'types/themes';
import { Meta } from 'utils/types';
import { PropertyTypeFilter } from 'contexts/preferences/listing-params';
import { ensureBackwardCompatibilityForHomeType } from './utils';

type CreateSavedSearchResponse = {
    data: Record<string, unknown>[];
    errors: {
      source?: {
        pointer?: string;
      };
      title?: string;
    }[];
};
  
type SavedSearchRequestParams = {
    page: {
      size: number;
      number?: number;
    };
    source: ThemeNames;
};

type GetSavedSearchResponse = {
    savedSearches: SavedSearchImpl[];
    meta: Meta;
};

export class SavedSearchApiManager {
  private static readonly SAVED_SEARCHES_ENDPOINT = '/services/api/v3/saved-searches';
  private constructor() {}

  static async get(params: SavedSearchRequestParams): Promise<GetSavedSearchResponse> {
    try {
      const { data, meta } = await endpoint<{ data: Record<string, unknown>[]; meta: Meta }>(
        SavedSearchApiManager.SAVED_SEARCHES_ENDPOINT, 
        HttpRequestMethodTypes.GET,
        params
      );
      const savedSearches = data
        .filter((p: any) => typeof p?.attributes?.['form-params']?.filter?.rental === 'boolean')
        .map(d => new SavedSearchImpl(d));
      return { savedSearches, meta };
    } catch {
      return {
        savedSearches: [] as SavedSearch[],
        meta: { totalPages: 0, totalCount: 0, pageNumber: 0, pageSize: 0 },
      };
    }
  }

  /**
   * Creates a new saved search from a partial saved search object.
   * 
   * @param search A partial object representing the saved search to create.
   * @returns The created saved search.
   * @throws Will throw an error if the saved search returns an error.
   */
  static async create(search: Partial<SavedSearch>) {

    // Remove properties that should not be sent when creating a new saved search
    delete search.id;
    delete search.imageUrl;
    delete search.listingsCount;
    delete search.createdAt;
    delete search.updatedOn;
    delete search.path;

    // Ensure backwards compatibility with deprecated property types
    const homeTypeFilter = ensureBackwardCompatibilityForHomeType(search.formParams.filter.homeType);
    search.formParams.filter.homeType = homeTypeFilter;
    
    const response = await endpoint<CreateSavedSearchResponse>(
      SavedSearchApiManager.SAVED_SEARCHES_ENDPOINT,
      HttpRequestMethodTypes.POST,
      {
        data: {
          type: 'saved-searches',
          attributes: dasherizeKeys(search),
        },
      }
    );
    if ('errors' in response) {
      throw {
        errors: response.errors.map(({ source, title }) => {
          return {
            attribute: camelize((source?.pointer || '').replace('/data/attributes/', '')),
            message: title,
          };
        }),
      };
    }
    return response;
  }

  static async delete(id: string) {
    await endpoint(`${SavedSearchApiManager.SAVED_SEARCHES_ENDPOINT}/${id}`, HttpRequestMethodTypes.DELETE);
  }

  /**
   * Updates an existing saved search.
   * 
   * @param search A partial object representing the saved search to update.
   * @throws Will throw an error if the saved search ID is not provided.
   */
  static async update(search: Partial<SavedSearch>) {
    if (!search.id) {
      throw new Error('Saved search ID is required to update a saved search.');
    }

    // Ensure backwards compatibility with deprecated property types
    const homeTypeFilter = ensureBackwardCompatibilityForHomeType(search.formParams.filter.homeType);
    search.formParams.filter.homeType = homeTypeFilter;

    await endpoint(`${SavedSearchApiManager.SAVED_SEARCHES_ENDPOINT}/${search.id}`, HttpRequestMethodTypes.PATCH, { data: { attributes: search }});
  }
}